<template>
  <v-row dense>
    <v-col cols="12">
      <v-data-table
          class="pa-1 text-xs-center data_table"
          :search="appBarSearch"
          :no-data-text="$t('no-data-available')"
          :items="medicalTeams"
          fixed-header
          :loading="loading"
          dense
          :height="pageHeight(160)"
          group-by="[id]"
          ref="table"
          :footer-props="footerProps"
          :items-per-page="-1"
      >
        <template v-slot:[`group.header`]="{ group, headers, items, isOpen, toggle }">
          <td @click="toggle" class="pa-2 text-left medical-teams-data-table pointer" :colspan="7">
            <v-row dense class="my-n3">
              <v-col cols="4">
                <v-btn small icon :ref="group" :data-open="isOpen" class="color-black">
                  <v-icon small v-if="isOpen">mdi-arrow-down</v-icon>
                  <v-icon v-else small>mdi-arrow-right</v-icon>
                </v-btn>
                <span class="font-weight-medium">{{ items[0].patientFullname }} {{ $t("medicalTeam") }}</span>
              </v-col>
              <v-col cols="2">
                <v-chip pill x-small color="primary" class="ma-2 text--black custom-chip-size" label>
                  {{ items[0].membersList.length }} {{ items[0].membersList.length > 1 ? $t('members') : $t('member')}}
                </v-chip>
              </v-col>
              <v-col class="d-flex align-center">
                <img class="mr-1" height="18px" :src="patient_black" />
                <span class="font-size12-5 font-weight-medium">{{ items[0].patientFullname }}</span>
              </v-col>
              <v-col class="text-right">
                <v-tooltip left color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon size="18" @click="navigateToPatientDetails(items[0].patientId)" color="main_black" v-on="on" style="margin-top:5px;">mdi-open-in-new</v-icon>
                  </template>
                  <span>{{ $t('show-patient-details') }}</span>
                </v-tooltip>
                <v-menu left offset-y transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="black" v-bind="attrs" v-on="on">
                      <v-icon class="mt-1">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item link dense @click="synchronizeDrawers(items[0])">
                      <v-icon  size="18" color="main_black">mdi-pencil</v-icon>
                      <v-list-item-content class="font-size14 font-weight-medium">{{ $t("edit") }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item="{ item }">
          <tbody>
          <td v-for="(header,index) in headers" :key="index" class="secondary" :class="header.id===6 ? 'text-right pr-2' : 'text-left'" style="width:400px;">
            <span class="font-weight-medium font-size11" :class="header.id ===1 ? 'pl-7' :'' ">{{ header.text }}</span>
          </td>
          <tr v-for="(member) in item.membersList" :key="member.patientId">
            <td class="text-left pl-2 color-black billings-border-bottom font-size12-5">
                  <img  v-if="member.roleId === 4" :src="nurse_icon(member.status)" color="main_black" height="17px"/>
                  <img  v-else-if="member.roleId === 3" :src="doctor_icon(member.status)" color="main_black" height="17px"/>
                  <span class="font-weight-medium text-left font-size12-5" >{{ member.firstName }} {{member.lastName}}</span>
            </td>
            <td class="text-left font-weight-medium billings-border-bottom font-size12-5" style="border-bottom: none;">{{ member.roleId === 3 ? $t('doctor') : $t('nurse') }}</td>
            <td class="text-left color-black billings-border-bottom font-size12-5" style="border-bottom: none">
              <v-icon x-small color="main_black" class="mr-1 ">mdi-email</v-icon>{{ member.email }}
            </td>
            <td class="text-left billings-border-bottom font-size12-5" style="border-bottom: none; color:black;">
              <v-icon x-small color="main_black" class="mr-1">mdi-phone</v-icon>{{ member.phone }}
            </td>
            <td class="text-left billings-border-bottom font-size12-5" style="border-bottom: none;">{{ item.hospitalName }}</td>
            <td class="billings-border-bottom">
              <span v-if="member.roleId === 3">
                <span v-if="member.isPrimaryDoctor">
                    <v-icon color="black" class="mdi mdi-check-circle pr-6 mdi-18px float-right"></v-icon>
                </span>
<!--                <span v-else>-->
<!--                    <v-icon color="black" class="mdi mdi-close-circle pr-6 mdi-18px float-right"></v-icon>-->
<!--                </span>-->
              </span>
            </td>

          </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { doctorBlack, doctorSecondary, nurseBlack, nurseSecondary, patientBlack } from '@/assets';
export default {
	data () {
		return {
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      isOpen: true,
      loading: true,
			hospitalName: '',
			doctor_black: doctorBlack,
      headers: [
        { id: 1, text: this.$t('name'), sortable: false, class: 'color_header ', align: 'center' },
        { id: 2, text: this.$t('header-role'), sortable: false, class: 'color_header' },
        { id: 3, text: this.$t('header-email'), sortable: false, class: 'color_header' },
        { id: 4, text: this.$t('header-phone'), sortable: false, class: 'color_header' },
        { id: 5, text: this.$t('header-hospital-name'), sortable: false, class: 'color_header' },
        { id: 6, text: this.$t('primary-doctor'), sortable: false, class: 'color_header', align: 'end' },
      ],
			doctor_secondary: doctorSecondary,
			nurse_black: nurseBlack,
			hospitalId: null,
			nurse_secondary: nurseSecondary,
			patient_black: patientBlack,
		};
	},
	computed: {
		...mapState({
      relatedUsers: (state) => state.hospitals.users,
      userData: (state) => state.authentication.userData,
			medicalTeamsRelated: (state) => state.medicalteam.medicalTeamsRelated,
		}),
		...mapGetters({
			getUser: 'authentication/getUser',
			pageHeight: 'pageHeight',
			appBarSearch: 'filterbar/getSearch',
			appBarMedicalSearch: 'filterbar/getMedicalTeam',
			roleName: 'authentication/getRole',
		}),
    medicalTeams () {
      const searchTerm = this.appBarMedicalSearch ? this.appBarMedicalSearch.toLowerCase() : '';
      if (this.medicalTeamsRelated != null && this.relatedUsers != null) {
        return this.medicalTeamsRelated.map(patientMedicalTeam => {
          const relatedUser = this.relatedUsers.find(user => user.id === patientMedicalTeam.patientId);
          if (relatedUser && relatedUser.status === 1) {
            patientMedicalTeam.membersList.forEach(doctor => {
              doctor.isPrimaryDoctor = (doctor.id === patientMedicalTeam.primaryDoctorId);
            });
            return patientMedicalTeam;
          } else {
            return null;
          }
        }).filter(item =>
            item && item.patientFullname && item.patientFullname.toLowerCase().includes(searchTerm),
        );
      } else {
        return [];
      }
    },
	},
	async mounted () {
		await this.showMedicalTeam();
    await this.toggleHeaders();
    this.loading = true;
		await this.$store.dispatch('hospitals/getRelatedUsersForHospital', this.userData.hospitalAssignedData.id).then(res => {
      this.loading = false;
    });
    this.toggleHeaders();
	},
  beforeDestroy () {
    this.$store.dispatch('filterbar/resetSearch');
  },
  methods: {
		navigateToPatientDetails (patientId) {
			this.$router.push({ name: 'Patient Details', params: { id: patientId } });
		},
		async synchronizeDrawers (item) {
			await this.$store.dispatch('medicalteam/getMedicalTeamForPatient', item.patientId);
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', { item, updateDrawer: true, page2: 'medicalteam' });
			this.loading = false;
		},
		async showMedicalTeam () {
			if (this.roleName === 'HOSPITAL_ADMIN') {
				await this.$store.dispatch('medicalteam/getMedicalTeamForHospitalAdmin', this.getUser.id);
			} else if (this.roleName === 'DOCTOR') {
				const data = { nurseId: this.getUser.id, hospitalId: this.getUser.hospitalAssignedData.id };
				await this.$store.dispatch('medicalteam/getMedicalTeamForDoctor', data).then(res => {
        });
			} else if (this.roleName === 'NURSE') {
				const data = { nurseId: this.getUser.id, hospitalId: this.getUser.hospitalAssignedData.id };
				await this.$store.dispatch('medicalteam/getMedicalTeamForNurse', data);
			} else {
				await this.$store.dispatch('medicalteam/getMedicalTeamForHospitalAdmin', this.getUser.id);
			}
		},
		doctor_icon (item) {
			switch (item) {
			case 1:
				return this.doctor_black;
			default:
				return this.doctor_secondary;
			}
		},
		nurse_icon (item) {
			switch (item) {
			case 1:
				return this.nurse_black;
			default:
				return this.nurse_secondary;
			}
		},
    toggleHeaders () {
      const table = this.$refs.table;
      if (table) {
        const openCache = table?.$vnode.componentInstance.openCache;
        if (openCache) {
          Object.keys(openCache).forEach(group => {
            this.$set(openCache, group, false);
          });
        }
      }
    },
	},
};
</script>
